<template>
  <div class="publisher-statistic" :class="{'_loading' : isLoadingLists}">
    <div v-show="isLoadingLists" class="publisher-statistic__loader">
      <b-spinner variant="primary" label="Spinning" />
    </div>
    <b-card v-if="!isLoadingLists" no-body class="publisher-statistic__filters" :class="{'_loading' : isLoadingLists}">
      <b-row class="my-2 px-2 justify-content-between">
        <b-col sm="4">
          <DropDownFilter :on-change="pickPublisher"
                          :facets="publishersList"
                          :state="queryObj.publishers"
                          track-by="id"  name="publishers" label="Паблишеры"/>
        </b-col>
        <b-col sm="4">
          <DropDownFilter :on-change="setFilter"
                          :facets="platformsList"
                          :state="queryObj.platforms"
                          track-by="id"  name="platforms" label="Площадки"/>
        </b-col>
        <b-col sm="4">
          <DropDownFilter :on-change="setFilter"
                          :facets="placementsList"
                          :state="queryObj.placements"
                          track-by="id"  name="placements" label="Размещения"/>
        </b-col>
      </b-row>
      <b-row class="px-2 justify-content-between">
        <b-col sm="4">
          <DropDownFilter :on-change="setFilter"
                          :facets="advertisementTypesList"
                          :state="queryObj.advertisement_types"
                          track-by="id"  name="advertisement_types" label="Тип рекламы"/>
        </b-col>
        <b-col sm="4">
          <DropDownFilter :on-change="setFilter"
                          :facets="devicesList"
                          :state="queryObj.device_types"
                          track-by="id"  name="device_types" label="Тип устройства"/>
        </b-col>
        <b-col sm="4">
          <DropDownFilter :on-change="setFilter"
                          :facets="geoList"
                          :state="queryObj.geos"
                          track-by="id"  name="geos" label="ГЕО"/>
        </b-col>
      </b-row>
      <b-row class="mt-2 px-2 justify-content-between">
        <b-col sm="4">
          <DropDownFilter :on-change="pickOS"
                          :facets="osList"
                          :state="queryObj.oses"
                          track-by="id"  name="oses" label="ОС"/>
        </b-col>
        <b-col sm="4">
          <DropDownFilter :on-change="pickBrowser"
                          :facets="browsersList"
                          :state="queryObj.browsers"
                          track-by="id"  name="browsers" label="Браузеры"/>
        </b-col>
        <b-col sm="4">
          <DropDownFilter :on-change="setFilter"
                          :facets="advertisersList"
                          :state="queryObj.dsps"
                          track-by="id"  name="dsps" label="DSP"/>
        </b-col>
      </b-row>
      <b-row class="mt-2 px-2">
        <b-col sm="4">
          <DropDownFilter :on-change="setFilter"
                          :facets="osVersionsList"
                          :state="queryObj.os_versions"
                          track-by="id"  name="os_versions" label="Версия ОС"/>
        </b-col>

        <b-col sm="4">
          <DropDownFilter :on-change="setFilter"
                          :facets="browsersVersionsList"
                          :state="queryObj.browser_versions"
                          track-by="id"  name="browser_versions" label="Версия браузера"/>
        </b-col>


        <b-col sm="4">
          <DropDownFilter :on-change="setFilter"
                          :facets="campaignsList"
                          :state="queryObj.campaigns"
                          track-by="id"  name="campaigns" label="РК"/>
        </b-col>
      </b-row>
      <b-row class="mt-2 px-2 d-flex justify-content-end">
        <b-col sm="4" class="d-flex justify-content-end align-items-end">
          <b-button :disabled="isLoadingLists" variant="primary" @click="fetchStatistic">Загрузить</b-button>
          <b-button class="ml-2" @click="resetFilters">Сброс</b-button>
        </b-col>
      </b-row>

      <b-row class="my-2 px-2 justify-content-between height-350">
        <b-col sm="6" class="px-1">
        <b-form-group label="Группировать по">
          <b-form-radio-group
              class="group-by d-flex flex-column flex-wrap height-150"
              id="group"
              @change="changeGroup"
              v-model="queryObj.group"
              :options="groupOptions"
              name="group"
              stacked
          ></b-form-radio-group>
        </b-form-group>
        </b-col>
        <div class="col-sm-4">
          <MultiselectWithValidation name="fields"
                                     :state="pickedFields"
                                     :facets="(data && data.field) || []"
                                     :on-change="sortFields"
                                     label-name="label"
                                     track-by="key"
                                     validate-name="fields"
                                     no-options-placeholder="Сначала загрузите статистику"
                                     label="Показать">
          </MultiselectWithValidation>
        </div>
      </b-row>
      <b-row class="my-2 px-2">
        <b-col sm="4">
          <b-form-group label="Выберите даты">
            <date-range-picker
                :disabled="isLoadingLists"
                id="datepicker"
                ref="picker"
                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                :maxDate="todayDate"
                autoApply
                lang="ru"
                v-model="dateRange"
                @update="setDate"
                linkedCalendars
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!--      <div class="publisher-statistic__charts">-->
      <!--        <b-row>-->
      <!--            <b-col v-for="chart in chartsData" sm="6">-->
      <!--          <LineChart-->
      <!--              :chart-data="chart.series"-->
      <!--              :chart-options="chartOptions"-->
      <!--          />-->
      <!--            </b-col>-->
      <!--        </b-row>-->
      <!--      </div>-->
      <div ref="scroll">
        <table-lazy :error="!!error" :is-loading="isLoading" :is-loading-more="isLoadingMore">
          <statistics-table v-if="data" :statistics="data.result" :fields="sortedFields" :sums="data.sum" :group="queryObj.group" />
        </table-lazy>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormSelect,
  BRow,
  BSpinner
} from 'bootstrap-vue'
import StatisticsTable from "@/components/StatisticsTable"
import TableLazy from '@/components/TableLazy'
import DropDownFilter from "@/components/DropDownFilter"
import {usePage} from '@/use/page'
import {infiniteScroll} from "@/use/infiniteScroll"
import MultiselectWithValidation from "@/components/MultiselectWithValidation"
import {
  fetchAdvertisementType,
  fetchBrowsers,
  fetchDate,
  fetchDeviceList,
  fetchGeoList,
  fetchOSList,
  fetchPlacementsList,
  fetchPlatformsList,
  fetchPublishersList,
  fetchAdvertisersList,
  fetchOSVersionsList,
  fetchBrowserVersionsList, fetchCampaignsList
} from "@/api/lists"
import {ref} from "@vue/composition-api"
import DateRangePicker from '@lexankh/vue2-daterange-picker'
import {statisticQueries} from "@/use/statisticQueries"
import {fetchAdminStatistics} from "@/api/statistics"
import {objToQuery} from "@core/utils/utils"
// import LineChart from "@core/components/statistics-cards/LineChart"

export default {
  name: 'AdminStatistic',
  components: {
    // LineChart,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButtonGroup,
    BButton,
    BFormSelect,
    BFormGroup,
    BFormRadioGroup,
    BFormDatepicker,
    StatisticsTable,
    TableLazy,
    DropDownFilter,
    DateRangePicker,
    MultiselectWithValidation
  },
  data() {
    return {
      search: '',
      activeFilter: null,
      pickedFields: null,
      campaigns: [],
      changedGroupState: false,
    }
  },
  computed: {
    sortedFields() {
      const fields = this.data.field.filter(obj => this.pickedFields.indexOf(obj.key) !== -1);

      this.$store.dispatch('statistics/SET_FIELDS', fields);
      return fields
    }
  },
  watch: {
    data() {
      this.initialSortState = this.$store.state.statistics.sortedFields;
      if(this.changedGroupState) {
        this.changedGroupState = false;
        this.pickedFields = (this.initialSortState.length && this.initialSortState.map(obj => obj.key)) || this.data?.field?.map(obj => obj.key);
        this.pickedFields.unshift(this.data.field[0].key)
      } else {
        this.pickedFields = (this.initialSortState.length && this.initialSortState.map(obj => obj.key)) || this.data?.field?.map(obj => obj.key);
      }
    }
  },
  setup(_, ctx) {

    const { isLoading, data, error, loadMoreData, isLoadingMore, fetchPageData } = usePage(fetchAdminStatistics, _, ctx, 'notPreload')
    const { scroll } = infiniteScroll(loadMoreData, isLoadingMore)
    let { queryObj, fetchStatistic } = statisticQueries(fetchPageData, ctx)

    //lists
    const publishersList = ref([])
    const platformsList = ref([])
    const placementsList = ref([])
    const advertisementTypesList = ref([])
    const advertisersList = ref([])
    const campaignsList = ref([])
    const devicesList = ref([])
    const geoList = ref([])
    const osList = ref([])
    const browsersList = ref([])
    const browsersVersionsList = ref([])
    const osVersionsList = ref([])
    const currentDateString = ref(null)
    const todayDate = ref(null)
    const dateRange = ref({
      startDate: new Date(),
      endDate: new Date()
    })
    const initialFilterState = ref({})

    //charts
    const fieldsIgnoreList = ["date", "placement"];
    const chartOptions = {
      xaxis: {
        type: 'datetime',
        labels: { show: true },
      }
    }

    const chartsData = ref([])

    const groupOptions = [
      { text: 'Дата', value: 'date' },
      { text: 'Паблишер', value: 'publisher' },
      { text: 'Площадка', value: 'platform' },
      { text: 'РК', value: 'campaign' },
      { text: 'Размещение', value: 'placement' },
      { text: 'ГЕО', value: 'geo' },
      { text: 'Девайс', value: 'device' },
      { text: 'ОС', value: 'os' },
      { text: 'Версия ОС', value: 'osVersion' },
      { text: 'Браузер', value: 'browser' },
      { text: 'DSP', value: 'dsp' },
      { text: 'Версия браузера', value: 'browserVersion' },
    ]

    const isLoadingLists = ref(true)
    Promise.all([
      fetchPublishersList(null, 1), // role = 1 for admin
      fetchPlatformsList(),
      fetchPlacementsList(null, 1),
      fetchAdvertisementType(),
      fetchDeviceList(),
      fetchGeoList(),
      fetchOSList(),
      fetchBrowsers(),
      fetchDate(),
      fetchAdvertisersList(null, 1),
      fetchCampaignsList()])
        .then(values => {
          publishersList.value = values[0].data.data
          platformsList.value = values[1].data.data
          placementsList.value = values[2].data.data
          advertisementTypesList.value = values[3].data.data
          devicesList.value = values[4].data.data
          geoList.value = values[5].data.data
          osList.value = values[6].data.data
          browsersList.value = values[7].data.data
          currentDateString.value = values[8].data.current_date
          advertisersList.value = values[9].data.data
          campaignsList.value = values[10].data.data

          // set actual date
          todayDate.value = new Date(currentDateString.value)
          dateRange.value.endDate = todayDate.value;
          const weekEarlier = todayDate.value.getDate() - 7
          dateRange.value.startDate.setDate(weekEarlier)

          queryObj.start_date = dateRange.value.startDate.toLocaleDateString('en-CA');
          queryObj.end_date = dateRange.value.endDate.toLocaleDateString('en-CA');

        })
        .finally(() => {
          isLoadingLists.value = false;
          initialFilterState.value = Object.assign({}, queryObj);
        })


    // charts make

    const makeChartsData = () => {

      chartOptions.xaxis.categories = data.value.result.map(obj => obj.date)

      data.value.field.forEach((field) => {

        if(fieldsIgnoreList.includes(field.key)) return;

        const fieldObj = {};
        const yAxis = data.value.result.map(obj => obj[field.key])
        fieldObj.key = field.key;
        fieldObj.label = field.label;
        fieldObj.series = [{
          name: field.label,
          data: [...yAxis]
        }]
        chartsData.value.push(fieldObj)
      })
    }

    // watch(
    //     () => data,
    //     () => {
    //
    //       if(data.value.field.length) {
    //         makeChartsData()
    //       }
    //     },
    //     {deep: true}
    // )

    // filter handling

    const setFilter = (value, name) => {
      queryObj[name] = value
    }

    const pickPublisher = (value, name) => {

      isLoadingLists.value = true;

      setFilter(value, name);

      Promise.all([
        fetchPlatformsList(objToQuery({'publisher': [...queryObj.publishers]})),
        fetchPlacementsList(objToQuery({'publisher': [...queryObj.publishers]}))])
          .then(values => {
            platformsList.value = values[0].data.data
            placementsList.value = values[1].data.data
          })
          .finally(() => {
            isLoadingLists.value = false;
          })
    }

    const pickOS = (value, name) => {
      isLoadingLists.value = true;
      setFilter(value, name);

      fetchOSVersionsList(objToQuery({'oses': [...queryObj.oses]}))
          .then(res => {
            osVersionsList.value = res.data.data
          })
          .finally(() => {
            isLoadingLists.value = false;
          })
    }

    const pickBrowser = (value, name) => {
      isLoadingLists.value = true;
      setFilter(value, name);

      fetchBrowserVersionsList(objToQuery({'browsers': [...queryObj.browsers]}))
          .then(res => {
            browsersVersionsList.value = res.data.data
          })
          .finally(() => {
            isLoadingLists.value = false;
          })
    }

    // set date to queryObj
    const setDate = (value) => {
      queryObj.start_date = value.startDate.toLocaleDateString('en-CA');
      queryObj.end_date = value.endDate.toLocaleDateString('en-CA');
    }

    const resetFilters = () => {
      ctx.emit('resetFilters');
      Object.assign(queryObj, initialFilterState.value)
    }

    return { isLoading,
      data,
      error,
      loadMoreData,
      isLoadingMore,
      fetchPageData,
      scroll,
      queryObj,
      isLoadingLists,
      publishersList,
      platformsList,
      placementsList,
      advertisementTypesList,
      advertisersList,
      devicesList,
      geoList,
      osList,
      browsersList,
      campaignsList,
      groupOptions,
      todayDate,
      currentDateString,
      setFilter,
      pickPublisher,
      dateRange,
      fetchStatistic,
      setDate,
      chartOptions,
      chartsData,
      browsersVersionsList,
      osVersionsList,
      pickBrowser,
      pickOS,
      initialFilterState,
      resetFilters
    }
  },

  methods: {
    resetFields() {
      this.$store.dispatch('statistics/RESET_FIELDS');
    },
    sortFields(value, name) {
      this.pickedFields = value;
    },
    changeGroup() {
      this.changedGroupState = true;
    }
  },

  created() {
    this.resetFields();
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@lexankh/vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
<style lang="scss" scoped>
.multiselect, .multiselect__input, .multiselect__single {
  font-size: 14px;
}
//
//.vue-daterange-picker {
//  min-width: 100%;
//}

.publisher-statistic {
  position: relative;

  &._loading {
    pointer-events: none;
  }

  &__loader {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 45%;
    width: 100%;
    height: 100%;
  }

  &__filters {

    &._loading {
      filter: blur(4px)
    }
  }
}
</style>

<style lang="scss">

.reportrange-text.form-control {
  padding: 8px 40px 0 8px;
  border: 1px solid #e8e8e8;
}

.custom-control {
  cursor: pointer;
}
</style>

